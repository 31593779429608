import React, { Component } from "react";
import { Navbar, Nav, Form, FormControl, Button, Image, ButtonToolbar} from "react-bootstrap";
import Pic1 from "./../Images/Pic1.jpg";
import CarouselPage from "./Carousel";


class Homepage extends Component {
  render() {
    return (
      <div style={{ backgroundColor: "powderblue" }}>
      <Navbar bg="dark" variant="dark">
    <Navbar.Brand href="#home">Ozairi</Navbar.Brand>
    <Nav className="mr-auto">
      <Nav.Link href="#home">Yousif AlOzairi</Nav.Link>
      <Nav.Link href="#AboutMe">Projects</Nav.Link>
      <Nav.Link href="#pricing">Extras</Nav.Link>
    </Nav>

    <Form inline>
      <FormControl type="text" placeholder="Search" className="mr-sm-2" />
      <Button variant="outline-info">Search</Button>
    </Form>
  </Navbar>
  
  <br />
        <h1 style={{ color: "green", align: "center"}}>Welcome to Yousif AlOzairi's Portfolio!</h1>
       
        &nbsp;
  <p style={{ color: "darkgreen", align: "center"}}> I am a 10th grade SLC Student in The American School of Kuwait. Born on January 24th 2004, I am 15 years old. Please enjoy my portfolio website :) </p>
  

  <hr />
  &nbsp;
  <br />

  <h3 style={{ color: "purple", align: "center"}}>SLC Interdisciplinary Projects:</h3>

<p style={{ color: "purple", align: "center"}}>The following are previews of all four of my interdisciplinary projects so far this year. Links to each project file can be found down below. Enjoy!</p>
        
        <CarouselPage />

        &nbsp;

        <h3 style={{ color: "DarkOrange", align: "center"}}><u>Project Links:</u></h3>

        <p style={{ color: "DarkOrange", align: "center"}}>Below you can find links to all of the aforementioned projects</p>
        &nbsp;

        <ButtonToolbar>
          
          <Button variant="primary" size="lg">
          <u>The Civilization Game</u>
          </Button>
          &nbsp;
      
          <Button variant="primary" size="lg">
          <u>The Bazaar</u>
          </Button>
          &nbsp;
      
          <Button variant="primary" size="lg">
          <u>The Ink Times Newspaper</u>
          </Button>
          &nbsp;
      
          <Button variant="primary" size="lg">
          <u>The Lab Report</u>
          </Button>
          &nbsp;
      
          <Button variant="primary" size="lg">
          <u>The 20-Time Speech on Censorship</u>
          </Button>
          &nbsp;
      
          <Button variant="primary" size="lg">
          <u>The 19th Century Science Report</u>
          </Button>
          &nbsp;
      
          <Button variant="primary" size="lg">
          <u>The Russia 2020 Solanum Outbreak Survival Plan</u>
          </Button>
          &nbsp;
      
          <Button variant="primary" size="lg">
          <u>The Presidential Mystery</u>
          </Button>
          &nbsp;
      
        </ButtonToolbar>

  <br />
        <p style={{ color: "Blue", align: "center"}}><h5> Alternative Links:</h5>
        &nbsp;
        <b>The Civilization Game:</b> <a href="https://www.icloud.com/keynote/0fzMrX9Uc0q_S-8YD3GWErAlg#Interdisciplinary_Project_final_draft" class="button" style={{ color: "Blue", align: "center"}}>https://www.icloud.com/keynote/0fzMrX9Uc0q_S-8YD3GWErAlg#Interdisciplinary_Project_final_draft</a></p>
        &nbsp;
        <p style={{ color: "Blue", align: "center"}}> <b>The Bazaar:</b> <a href="https://drive.google.com/file/d/1UqCU5qCmMxeCyQwn5W41uEXrNwJm7sRg/view?usp=sharing" class="button" style={{ color: "Blue", align: "center"}}>https://drive.google.com/file/d/1UqCU5qCmMxeCyQwn5W41uEXrNwJm7sRg/view?usp=sharing</a></p>
        &nbsp;
        <p style={{ color: "Blue", align: "center"}}> <b>The Ink Times Newspaper:</b> <a href="https://drive.google.com/file/d/12X3hN26IktStJ5tVXI2ABNeWTw4BUL7M/view?usp=sharing" class="button" style={{ color: "Blue", align: "center"}}>https://drive.google.com/file/d/12X3hN26IktStJ5tVXI2ABNeWTw4BUL7M/view?usp=sharing</a></p>
        &nbsp;
        <p style={{ color: "Blue", align: "center"}}> <b>The Lab Report:</b> <a href="https://www.icloud.com/keynote/0QGXBFIusG2-7bI2_8EQeBFOQ#Yousif_&_Inyoung_Lab_Report" class="button" style={{ color: "Blue", align: "center"}}>https://www.icloud.com/keynote/0QGXBFIusG2-7bI2_8EQeBFOQ#Yousif_&_Inyoung_Lab_Report</a></p>
        &nbsp;
        <p style={{ color: "Blue", align: "center"}}> <b>20-Time Speech on Censorship:</b> <a href="https://drive.google.com/drive/folders/10tqi1WDS8W12yPW-wn1waVZz5j9GlFgv?usp=sharing" class="button" style={{ color: "Blue", align: "center"}}>https://drive.google.com/drive/folders/10tqi1WDS8W12yPW-wn1waVZz5j9GlFgv?usp=sharing</a></p>
        &nbsp;
        <p style={{ color: "Blue", align: "center"}}> <b>The 19th Century Science Report:</b> <a href="https://www.icloud.com/pages/0iE-KEWSGhiZhqfuQxIPWux6w#Science_Report_December_1899" class="button" style={{ color: "Blue", align: "center"}}>https://www.icloud.com/pages/0iE-KEWSGhiZhqfuQxIPWux6w#Science_Report_December_1899</a></p>
        &nbsp;
        <p style={{ color: "Blue", align: "center"}}> <b>The Russia 2020 Solanum Outbreak Survival Plan:</b> <a href="https://docs.google.com/document/d/1QoMKiCbQ1I7WIoFWW2TE34UzODbb935pbpi69ckEpJ4/edit?usp=sharing" class="button" style={{ color: "Blue", align: "center"}}>https://docs.google.com/document/d/1QoMKiCbQ1I7WIoFWW2TE34UzODbb935pbpi69ckEpJ4/edit?usp=sharing</a></p>
        &nbsp;
        <p style={{ color: "Blue", align: "center"}}> <b>The Presidential Mystery:</b> <a href="https://drive.google.com/drive/folders/10uZEA-iBLRImVVOTMDBfX8KT9PsneubB?usp=sharing" class="button" style={{ color: "Blue", align: "center"}}>https://drive.google.com/drive/folders/10uZEA-iBLRImVVOTMDBfX8KT9PsneubB?usp=sharing</a></p>
        &nbsp;
<br />



<hr />
  <h2 style={{ color: "Purple", align: "center"}}><u>About Me</u></h2>


        <Image src={Pic1} fluid />

<br />
&nbsp;

<p style={{ color: "Purple", align: "center"}}>As you know, I am Yousif AlOzairi, a tenth-grade student in The American School of Kuwait (ASK). I was born in Aberdeen (Scotland) on January 24th, 2004, however, my family quickly moved to a more permanent home in Newcastle Upon Tyne. I would spend the majority of my childhood growing up in Newcastle for almost eleven years (up to 2014) until we finally moved out. Prior to this, I learned so much from my experience in the UK and will be ever greatful for all of the life skills it has taught me. However, in August of 2014, we finally moved to our family's roots; Kuwait. We were inevitably going to move to Kuwait eventually since all of our family live there and, while living in Newcastle, we were always meant to move back at some point. In fact, we actually stayed in Newcastle much longer than we were planning on -so in 2014 we finally decided it was time. I have three siblings; an older sister; a younger sister; and a younger brother. In Kuwait, we moved to The American School of Kuwait which is where we are now. Moving from the British school system to the American was a tough transition but, in hindsight, it really allowed us all to imprpve as we while we were gaining new perks from the American school system (such as public speaking, presenting, and project-making), we had retained much important skills taught in the British system (such as English, test-taking, and grammar). Through this, I was able to learn even more, gaining extra essential skills to apply in my student life, and I truly believe this is a huge reason for much of my successes. Other than that, I am a 15-year old ASK student (currently in grade 10) currently getting through their SLC (Small Learning Community) Program. My favorite color is green, the picture above is me at three years old, and I have most recently been interested in medicine and web/app development! </p>


        <div style={{ height: 10, backgroundColor: "powderblue" }} />
      </div>
    );
  }





}

export default Homepage;